import React from 'react';
import "./location.scss";
import { SectionsComp } from "../..";
import LocationOnIcon from '@mui/icons-material/LocationOn';
import { MapContainer, TileLayer, Marker, Popup } from 'react-leaflet';
import 'leaflet/dist/leaflet.css';
import { useTranslation } from 'react-i18next';


function Location() {
    
    const { t } = useTranslation();


    const locations = [
        { id: 1, name: 'فرع دهوك', position: [36.876865, 42.945999] },
        { id: 2, name: 'فرع اربيل', position: [36.178246, 43.887936] },
        { id: 3, name: 'فرع الموصل', position: [36.3705611, 43.1607069] },
        { id: 3, name: 'موقع التحميل', position: [36.6878352, 43.0210838] },
    ];

    return (

        <div className='location'>
            <SectionsComp section={t("locationT")} />
            <div className="locationContainer">
                <div className="locations">
                    <div className="loaction">
                        <p>{t("mosul")}</p>
                        <LocationOnIcon className='ico' />
                    </div>
                    <a href="https://maps.google.com/?q=36.178246,43.887936" target="_blank">
                        <div className="loaction">
                        <p>{t("erbial")}</p>
                        <LocationOnIcon className='ico' />
                    </div>
                    </a>
                    
                    <a href="https://maps.google.com/?q=36.876865,42.945999" target="_blank">
                        <div className="loaction">
                            <p>{t("duhok")}</p>
                            <LocationOnIcon className='ico' />
                        </div>
                    </a>

                    <a href="https://www.google.com/maps/place/Bariya,+Nineveh+Governorate/@36.6878352,43.0210838,16z/data=!3m1!4b1!4m6!3m5!1s0x40087ebcba762b9b:0x8f325bd1649e77e1!8m2!3d36.6874816!4d43.02002!16s%2Fg%2F11g_xj1_s?entry=ttu" target="_blank"
                        rel="noopener noreferrer"><div className="loaction" >
                            <p>{t("loadLocatoinT")}</p>
                            <LocationOnIcon className='ico' />
                        </div></a>

                </div>
                <div className="map">
                    <MapContainer className='mapview' center={[36.595689, 43.4639067]} zoom={8} style={{ height: '500px', width: '100%' }}>
                        <TileLayer url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png" />
                        {locations.map(loc => (
                            <Marker key={loc.id} position={loc.position}>
                                <Popup>{loc.name}</Popup>
                            </Marker>
                        ))}
                    </MapContainer>
                </div>
            </div>
        </div>
    )
}

export default Location;